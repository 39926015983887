import { capitalize, first, isEqual, last, map } from "lodash-es";
import type { Component, ComponentProps, JSXElement } from "solid-js";
import { LoadingView } from "~/components/LoadingView";
import { ModelGameHistory } from "~/components/ModelGameHistory";
import { PlansBrowser } from "~/components/PlansBrowser";
import { PreAdd } from "~/components/PreAdd";
import { RepertoireBrowser } from "~/components/RepertoireBrowser";
import { RepertoireOverview } from "~/components/RepertoirtOverview";
import { _animateSidebar } from "~/components/SidebarContainer";
import { Logo } from "~/components/icons/Logo";
import type { EnrichedComponent, SidebarComponent, SidebarView } from "~/types/View";
import { BACK_STACK, setBackStack } from "~/utils/signals/onBack";
import { APP_STATE, type AppState, BROWSING_STATE, UI, quick } from "./app_state";
import { clsx } from "./classes";
import { isChessmadra } from "./env";
import { MultiCallback } from "./multi_callback";
import { viewModes } from "./register_view_mode";

export type BrowsingMode =
	| "browse"
	| "build"
	| "review"
	| "mistakes"
	| "side_overview"
	| "overview"
	| "openings_report"
	| "model_games"
	| "play"
	| "plans_and_model_games";

export const modeToUI = (mode: BrowsingMode) => {
	const modeUiMapping: Record<BrowsingMode, string> = {
		browse: "Review",
		build: "Browse / add new",
		review: "Practice",
		side_overview: "Overview",
		openings_report: "Openings report",
		overview: "Home",
		model_games: "Model games",
		mistakes: "Opening mistakes",
		plans_and_model_games: "Plans & model games",
		play: "Play",
	};
	return modeUiMapping[mode];
};

type Stack = [UiState, AppState];

interface ReadonlyUiState {
	readonly mode: BrowsingMode;
}

export type UiState = ReturnType<typeof getInitialUiState> & ReadonlyUiState;

export interface NavBreadcrumb {
	text: JSXElement;
	unclickable?: boolean;
	onPress?: () => void;
}

export const getInitialUiState = () => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string) => {
		return quick((s) => {
			return fn([s.ui, s]);
		});
	};
	const get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return fn([APP_STATE().ui, APP_STATE()]);
	};
	const initialState = {
		mode: "overview" as BrowsingMode,
		backButtonCallback: new MultiCallback<() => void>(),
		loadUntil: <T,>(task: Promise<T>): Promise<T> => {
			return set(([s]) => {
				s.pushView(LoadingView);
				return task.then((x) => {
					set(([s]) => {
						s.animateSidebar("right");
						s.cutView();
					});
					return x;
				});
			});
		},
		getBreadCrumbs: () =>
			get(([s, appState]) => {
				const rs = appState.repertoireState;
				const homeBreadcrumb: NavBreadcrumb = {
					text: (
						<div
							class={clsx("square-5 col pr-10px -m-2 box-content items-center justify-center p-2")}
						>
							<Logo />
						</div>
					),
					onPress: () => {
						set(([_s, appState]) => {
							appState.repertoireState.backToOverview();
						});
					},
				};

				const breadcrumbs: NavBreadcrumb[] = [homeBreadcrumb];
				if (isChessmadra) {
					const activeTool = APP_STATE().trainersState.getActiveTool();
					if (activeTool === "blunder-puzzles") {
						breadcrumbs.push({
							text: "Blunderbash",
							onPress: () => {
								set(([_s, _appState]) => {
									UI().popView();
								});
							},
						});
					}
					return breadcrumbs;
				}
				if (rs.onboarding.isOnboarding) {
					return [];
				}
				const mode = s.mode;
				const repertoire = rs.browsingState.getActiveRepertoire();
				if (
					repertoire &&
					["browse", "build", "side_overview", "plans_and_model_games"].includes(mode)
				) {
					breadcrumbs.push({
						text: repertoire.name,
						onPress: () => {
							quick((_s) => {
								UI().backTo(RepertoireOverview);
								BROWSING_STATE().chessboard.resetPosition();
								BROWSING_STATE().setActiveRepertoire(repertoire.id);
								BROWSING_STATE().activeCourse.overview = undefined;
								BROWSING_STATE().activeCourse.details = undefined;
							});
						},
					});
				}
				const clickableModes: BrowsingMode[] = [
					"browse",
					"build",
					"plans_and_model_games",
					"model_games",
				];
				if (mode !== "side_overview" && mode !== "overview") {
					const unclickable = !clickableModes.includes(mode);
					breadcrumbs.push({
						text: capitalize(modeToUI(mode)),
						unclickable,
						onPress: unclickable
							? undefined
							: () => {
									quick((s) => {
										UI().clearViews();
										UI().pushView(
											mode === "browse"
												? RepertoireBrowser
												: mode === "plans_and_model_games"
													? PlansBrowser
													: mode === "model_games"
														? ModelGameHistory
														: PreAdd,
										);
										if (repertoire) {
											BROWSING_STATE().setActiveRepertoire(repertoire.id);
											s.repertoireState.browsingState.chessboard.resetPosition();
										}
									});
								},
					});
				}
				// if (courseOverview) {
				// 	breadcrumbs.push({
				// 		text: (
				// 			<div class="flex row gap-2 items-center">
				// 				<CourseAvatar course={courseOverview} size={20} />
				// 				<p class="">{courseOverview.name}</p>
				// 			</div>
				// 		),
				// 		unclickable: true,
				// 	});
				// }
				return breadcrumbs;
			}),
		sidebarStack: [] as SidebarView<Component | EnrichedComponent<any>, any>[],
		withoutAnimations: (fn: () => void) => {
			set(([s]) => {
				const originalSkipAnimations = s.skipAnimations;
				s.skipAnimations = true;
				fn();
				s.skipAnimations = originalSkipAnimations;
			});
		},
		currentView: (): SidebarView<any, any> | undefined => {
			return get(([s]) => {
				return last(s.sidebarStack);
			});
		},
		clearingViews: false,
		skipAnimations: false,
		clearViews: () => {
			return set(([s, _gs]) => {
				if (s.clearingViews) {
					return;
				}
				s.clearingViews = true;
				// order is important here! We need to clear out the stack after because some back actions mess with the stack
				const currentBackStack = BACK_STACK();
				currentBackStack.forEach((entry) => entry?.fn());
				s.clearingViews = false;
				s.sidebarStack = [];
				setBackStack([]);
				s.onViewsUpdated();
			});
		},
		hasView: (component: SidebarComponent<any>) => {
			return get(([s]) => {
				return s.sidebarStack.some((entry) => entry.component === component);
			});
		},
		ensureView: <T extends Component<any>>(
			view: T,
			opts?: { direction?: "left" | "right"; props?: ComponentProps<T> },
		) => {
			set(([s, _gs]) => {
				if (last(s.sidebarStack)?.component === view) {
					if (!isEqual(last(s.sidebarStack)?.props, opts?.props)) {
						s.replaceView(view, opts);
					}
					return;
				}
				s.pushView(view, opts);
			});
		},
		pushViewNoAnimation: <T extends Component<any>>(
			view: T,
			opts?: { props?: ComponentProps<T> },
		) => {
			set(([s, _gs]) => {
				s.sidebarStack.push({
					component: view,
					props: opts?.props ?? {},
				});
				s.onViewsUpdated();
			});
		},
		pushView: <T extends Component<any>>(
			view: T,
			opts?: { direction?: "left" | "right"; props?: ComponentProps<T> },
		) => {
			set(([s, _gs]) => {
				s.animateSidebar(opts?.direction ?? "right");
				s.sidebarStack.push({
					component: view,
					props: opts?.props ?? {},
				});
				// console.log("view?", view);
				s.onViewsUpdated();
			});
		},
		replaceView: (view: Component<any>, opts?: { direction?: "left" | "right"; props?: any }) => {
			set(([s, _gs]) => {
				s.animateSidebar(opts?.direction ?? "right");
				s.sidebarStack.pop();
				s.sidebarStack.push({ component: view, props: opts?.props ?? {} });
				s.onViewsUpdated();
			});
		},
		logSidebarStack: () => {
			return get(([s]) => {
				return [
					map(
						s.sidebarStack,
						(entry) => `${entry.component.name}(${JSON.stringify(entry.props, null, 2)})`,
					),
				].join(" > ");
			});
		},
		cutTo: (view: Component<any>) => {
			set(([s, _gs]) => {
				let hasCutAny = false;
				while (s.sidebarStack.length > 0) {
					if (last(s.sidebarStack)?.component === view) {
						if (hasCutAny) {
							s.onViewsUpdated();
							s.animateSidebar("left");
						}
						return;
					}
					s.sidebarStack.pop();
					hasCutAny = true;
				}
				s.pushView(view);
			});
		},
		// goes back to either of the views, defaulting to the first one in the list
		backToEither: (views: Component<any>[]) => {
			set(([s, _gs]) => {
				let hasBackedAny = false;
				s.skipAnimations = true;
				let _i = 0;
				while (s.sidebarStack.length > 0) {
					if (views.includes(last(s.sidebarStack)?.component!)) {
						if (hasBackedAny) {
							s.onViewsUpdated();
							s.animateSidebar("left");
						}
						s.skipAnimations = false;
						return;
					}
					s.backOne({ forceBack: true });
					hasBackedAny = true;
				}
				s.skipAnimations = false;
				s.pushView(first(views)!);
			});
		},
		backTo: (view: Component<any>) => {
			set(([s, _gs]) => {
				let hasBackedAny = false;
				s.skipAnimations = true;
				let _i = 0;
				while (s.sidebarStack.length > 0) {
					if (last(s.sidebarStack)?.component === view) {
						if (hasBackedAny) {
							s.onViewsUpdated();
							s.animateSidebar("left");
						}
						s.skipAnimations = false;
						return;
					}
					s.backOne({ forceBack: true });
					hasBackedAny = true;
				}
				s.skipAnimations = false;
				s.pushView(view);
			});
		},
		animateSidebar: (direction: "left" | "right") => {
			set(([s]) => {
				if (s.skipAnimations) {
					return;
				}
				_animateSidebar?.(direction);
			});
		},
		backsProcessing: new Set<number>(),
		getBackEntry: () => {
			return get(([s]) => {
				const currentBackStack = BACK_STACK();
				const backIndex = s.sidebarStack.length - 1;
				const backEntry = currentBackStack[backIndex];
				return backEntry;
			});
		},
		backOne: (opts?: { forceBack?: boolean }) => {
			set(([s, _gs]) => {
				const currentBackStack = BACK_STACK();
				const backIndex = s.sidebarStack.length - 1;
				const backEntry = currentBackStack[backIndex];
				if (s.backsProcessing.has(backIndex)) {
					if (opts?.forceBack) {
						s.sidebarStack.pop();
					}
					return;
				}
				if (backEntry) {
					s.backsProcessing.add(backIndex);
					if (backEntry.behavior === "animate" || opts?.forceBack) {
						s.animateSidebar("left");
						backEntry.fn();
						s.sidebarStack.pop();
						s.onViewsUpdated();
					} else {
						backEntry.fn();
					}
					s.backsProcessing.delete(backIndex);
				} else {
					s.animateSidebar("left");
					s.sidebarStack.pop();
					s.onViewsUpdated();
				}
			});
		},
		getActiveChessboard: () => {
			if (UI().mode === "openings_report") {
				return APP_STATE().repertoireState.openingReportsState.chessboard;
			}
			if (UI().mode === "review") {
				return APP_STATE().repertoireState.reviewState.chessboard;
			}
			if (UI().mode === "model_games") {
				return APP_STATE().repertoireState.modelGamesState.chessboard;
			}
			return APP_STATE().repertoireState.browsingState.chessboard;
		},
		cutView: () => {
			set(([s, _gs]) => {
				s.sidebarStack.pop();
				s.onViewsUpdated();
			});
		},
		popView: (opts?: { direction?: "left" | "right" }) => {
			set(([s, _gs]) => {
				s.animateSidebar(opts?.direction ?? "left");
				s.sidebarStack.pop();
				s.onViewsUpdated();
			});
		},
		onViewsUpdated: () => {
			set(([s]) => {
				let mode: BrowsingMode | undefined = "overview";
				s.sidebarStack.forEach((view) => {
					let modeOrFn = viewModes.get(view.component);
					if (modeOrFn) {
						if (typeof modeOrFn === "function") {
							mode = modeOrFn();
						} else {
							mode = modeOrFn;
						}
					}
				});
				s.mode = mode;
			});
		},
	};
	return initialState;
};
