import { destructure } from "@solid-primitives/destructure";
import { cloneDeep, isNil, take } from "lodash-es";
import odiff from "odiff";
import { type Accessor, type Owner, createSignal, getOwner, runWithOwner } from "solid-js";
import { type AdminState, getInitialAdminState } from "./admin_state";
import { type DebugState, getInitialDebugState } from "./debug_state";
import { isDevelopment } from "./env";
import type { InAppProductId } from "./in_app_purchases";
import { type NavigationState, getInitialNavigationState } from "./navigation_state";
import { type RepertoireState, getInitialRepertoireState } from "./repertoire_state";
import { type TrainersState, getInitialTrainersState } from "./trainers_state";
import { type UiState, getInitialUiState } from "./ui_state";
import { type UserState, getInitialUserState } from "./user_state";
import type { VisualizationState } from "./visualization_state";

import decircular from "decircular";
import { type SetStoreFunction, produce } from "solid-js/store";
import { _appState, _setAppState } from "./app_state_store";
import { type CardsState, getInitialChesscardsState } from "./chesscards_state";
import { SHOULD_DEBUG_STATE_UPDATES } from "./debug";
// import { type PlayState, getInitialPlayState } from "./play_state";

export interface InAppPurchaseState {
	products: Record<InAppProductId, CdvPurchase.Product>;
}

export interface AppState {
	adminState: AdminState;
	repertoireState: RepertoireState;
	cardsState: CardsState;
	ui: UiState;
	debugState: DebugState;
	navigationState: NavigationState;
	userState: UserState;
	trainersState: TrainersState;
	inAppPurchaseState: InAppPurchaseState;
	// playState: PlayState;
}

export let pendingState: AppState | null = null;

export const [owner, setOwner] = createSignal<Owner>(getOwner()!);

const set = <T>(fn: (state: AppState) => T): T => {
	if (pendingState) {
		return fn(pendingState!);
	}
	let res: T | null = null;
	const originalState = SHOULD_DEBUG_STATE_UPDATES ? cloneDeep(appState) : null;
	if (owner() === null) {
		// debugger;
	}
	setAppState(
		produce((state: AppState) => {
			return runWithOwner(owner(), () => {
				if (getOwner() === null) {
					// debugger;
				}
				if (getOwner() === null) {
					// debugger;
				}
				if (SHOULD_DEBUG_STATE_UPDATES) {
					console.debug(
						`%c[STORE UPDATE] %c`,
						"color: #639E62; font-weight: bold;",
						"color: black; font-weight: normal;",
					);
				}
				const stack = new Error().stack;
				pendingState = state;
				try {
					res = fn(state as AppState);
					if (getOwner() === null) {
						// debugger;
					}
					if (isDevelopment && SHOULD_DEBUG_STATE_UPDATES) {
						const diff = odiff(decircular(originalState!), decircular(cloneDeep(state)));
						console.debug(
							`%c[STORE UPDATE RESULT]: %c paths \n${take(diff, 5)
								.map((d: { path: (string | number)[] }) => `    ${d.path.join(".")}`)
								.join("\n")}`,
							"color: #639E62; font-weight: bold;",
							"color: salmon; font-weight: bold;",
							stack,
							diff,
						);
					}
				} catch (e) {
					console.error("error updating state!", e);
					if (isDevelopment) {
						// biome-ignore lint: debugger is fine here
						debugger;
					}
				} finally {
					pendingState = null;
				}
			});
		})!,
	);
	return res!;
};
const get = <T>(s: (_: AppState) => T): T => {
	if (pendingState) {
		return s(pendingState);
	}
	return s(appState);
};

const appState = _appState as AppState;
const setAppState = _setAppState as SetStoreFunction<AppState>;
if (isNil(appState?.repertoireState)) {
	const initialState: AppState = {
		cardsState: getInitialChesscardsState(set, get),
		repertoireState: getInitialRepertoireState(set, get),
		ui: getInitialUiState(),
		trainersState: getInitialTrainersState(set, get),
		inAppPurchaseState: {
			products: {} as Record<InAppProductId, CdvPurchase.Product>,
		},
		adminState: getInitialAdminState(set, get),
		debugState: getInitialDebugState(set, get),
		navigationState: getInitialNavigationState(set, get),
		userState: getInitialUserState(set, get),
		// playState: getInitialPlayState(),
	};
	setAppState(initialState);
}

export const useAppStateInternal = <T>(selector: (state: AppState) => T) => {
	return get((s) => selector(s));
};

export const useStateSliceDestructure = <Y, T extends any[]>(
	selector: (_: Y) => T,
	sliceSelector: (_: AppState) => Y,
): AccessorArray<T> => {
	const stateSlice = () => useAppStateInternal((s) => selector(sliceSelector(s)));
	return destructure(stateSlice, { memo: false });
};

type AccessorArray<T extends any[]> = {
	[K in keyof T]: Accessor<T[K]>;
};

export const useVisualizationState = <T extends any[]>(fn: (_: VisualizationState) => T) => {
	return useStateSliceDestructure(fn, (s) => s.trainersState.visualizationState);
};

export const useUserState = <T extends any[]>(fn: (_: UserState) => T) => {
	return useStateSliceDestructure(fn, (s) => s.userState);
};

export const useAdminState = <T extends any[]>(fn: (_: AdminState) => T) => {
	return useStateSliceDestructure(fn, (s) => s.adminState);
};

export const APP_STATE = () => pendingState ?? appState;
export const REVIEW_STATE = () => APP_STATE().repertoireState.reviewState;
// export const PLAY_STATE = () => APP_STATE().playState;
export const CARDS_REVIEW_STATE = () => APP_STATE().cardsState.reviewState;
export const USER_STATE = () => APP_STATE().userState;
export const ADMIN_STATE = () => APP_STATE().adminState;
export const TRAINERS_STATE = () => APP_STATE().trainersState;
export const BLUNDER_PUZZLES_STATE = () => APP_STATE().trainersState.blunderPuzzlesState;
export const REPERTOIRE_STATE = () => APP_STATE().repertoireState;
export const CARDS_STATE = () => APP_STATE().cardsState;
export const MODEL_GAMES_STATE = () => APP_STATE().repertoireState.modelGamesState;
export const OPENINGS_REPORT_STATE = () => APP_STATE().repertoireState.openingReportsState;
export const BROWSING_STATE = () => APP_STATE().repertoireState.browsingState;

if (typeof window !== "undefined") {
	// @ts-ignore
	window.appState = appState;
}

export const quick = <T>(fn: (_: AppState) => T): T => {
	return set(fn);
};

export const s = () => {
	return appState;
};

export const useMode = () => {
	return () => UI().mode;
};

export const UI = () => APP_STATE().ui;
