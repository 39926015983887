import { filter, isEmpty, isNil } from "lodash-es";
import { createMemo } from "solid-js";
import type { EnrichedComponent } from "~/types/View";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { registerViewMode } from "~/utils/register_view_mode";
import { useViewCoursesAction } from "~/utils/sharedActions";
import { trackEvent } from "~/utils/trackEvent";
import { CMText } from "./CMText";
import { RepertoireBuilder } from "./RepertoireBuilder";
import { Shortcuts } from "./Shortcuts";
import { type SidebarAction, SidebarActions, useBiggestGapActions } from "./SidebarActions";
import { ChooseImportSourceOnboarding } from "./SidebarOnboarding";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";

export const PreAdd: EnrichedComponent<{ bogus: any }> = (_props) => {
	const _repertoire = () => BROWSING_STATE().getActiveRepertoire()!;
	const side = () => BROWSING_STATE().activeSide;
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const biggestMiss = () => REPERTOIRE_STATE().repertoireGrades[activeRepertoireId()!]?.biggestMiss;
	const numMoves = () => REPERTOIRE_STATE().numMyMoves?.[activeRepertoireId()!] ?? 0;
	const empty = () => numMoves() === 0;
	const _bookmarks = () => {
		if (!USER_STATE().flagEnabled("bookmarks")) {
			return [];
		}
		let bookmarks = REPERTOIRE_STATE().autoBookmarks[activeRepertoireId()!];
		if (!bookmarks || isEmpty(bookmarks)) {
			return [];
		}
		return filter(bookmarks, (b) => b.repertoireId === activeRepertoireId()!);
	};
	const actions = createMemo(() => {
		let actions: SidebarAction[] = [];
		const missActions = useBiggestGapActions();
		actions = [
			...actions,
			...missActions.map((a) => ({ ...a, style: "primary" as SidebarAction["style"] })),
		];
		actions.push({
			onPress: () => {
				quick((_s) => {
					UI().pushView(RepertoireBuilder);
					BROWSING_STATE().setActiveRepertoire(activeRepertoireId());
					if (empty()) {
						trackEvent("side_overview.start_building");
					} else {
						trackEvent("side_overview.keep_building");
					}
					return;
				});
			},
			text: (
				<CMText>
					{empty()
						? "Start building your repertoire"
						: isNil(biggestMiss())
							? "Browse / add new moves"
							: "Browse / edit something else"}
				</CMText>
			),
			style: "primary",
		});
		actions.push({
			text: "Import",
			onPress: () => {
				quick((_s) => {
					trackEvent("side_overview.import");
					UI().pushView(ChooseImportSourceOnboarding, {
						props: { side: side() },
					});
				});
			},
			style: "secondary" as const,
		});
		const viewCoursesAction = useViewCoursesAction({
			epd: START_EPD,
			repertoireId: BROWSING_STATE().activeRepertoireId!,
		})();
		if (viewCoursesAction && !empty()) {
			actions.push({ ...viewCoursesAction, style: "secondary" });
		}
		return actions;
	});
	let _barType = () => (isNil(biggestMiss()) ? "mastery" : "coverage");
	return (
		<SidebarTemplate
			header={empty() ? "Start building your repertoire" : "Build your repertoire"}
			actions={[]}
			bodyPadding={false}
		>
			<SidebarActions actions={actions()} />
			<Spacer between={["actions", "actions"]} />
			<Shortcuts />
		</SidebarTemplate>
	);
};

registerViewMode(PreAdd, "build");
