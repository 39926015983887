import { every, filter, isEmpty } from "lodash-es";
import { createEffect, createMemo } from "solid-js";
import { Spacer } from "~/components/Space";
import { TableResponse } from "~/types/TableResponse";
import type { EnrichedComponent } from "~/types/View";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, quick } from "~/utils/app_state";
import { registerViewMode } from "~/utils/register_view_mode";
import { onBack } from "~/utils/signals/onBack";
import { c, stylex } from "~/utils/styles";
import { shouldUsePeerRates } from "~/utils/table_scoring";
import { trackEvent } from "~/utils/trackEvent";
import { BP, useResponsiveV2 } from "~/utils/useResponsive";
import { PreReview } from "./PreReview";
import { SidebarHeader } from "./RepertoireEditingHeader";
import { RepertoireMovesTable } from "./RepertoireMovesTable";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";

export const RepertoireBrowser: EnrichedComponent = function Responses() {
	const currentEpd = () => BROWSING_STATE().chessboard.getCurrentEpd();
	const activeSide = () => BROWSING_STATE().activeSide;
	const positionReport = () =>
		REPERTOIRE_STATE().positionReports[BROWSING_STATE().activeRepertoireId!]?.[currentEpd()];

	const currentSide = () => BROWSING_STATE().currentSide;
	const tableResponses = () =>
		filter(BROWSING_STATE().tableResponses, (tr: TableResponse) => !tr.repertoireMove?.isExcluded);

	const usePeerRates = () => shouldUsePeerRates(positionReport());
	const allLocalStockfish = () =>
		every(tableResponses(), (m) => TableResponse.getStockfishEvalSource(m) === "local") &&
		tableResponses().length > 0;
	const header = "What do you want to review?";
	const responsive = useResponsiveV2();

	const moveLog = () => BROWSING_STATE().chessboard.get((v) => v).moveLog;
	createEffect(() => {
		onBack(
			() => {
				quick((s) => {
					if (!isEmpty(moveLog())) {
						animateSidebar("left");
						s.repertoireState.browsingState.chessboard.backOne();
					} else {
						s.repertoireState.browsingState.reset();
					}
				});
			},
			isEmpty(moveLog()) ? "animate" : "stay",
		);
	});

	return (
		<div style={stylex(c.column, c.constrainWidth)}>
			<div class="padding-sidebar">
				<SidebarHeader>{header}</SidebarHeader>
			</div>
			<Spacer height={responsive().switch(20, [BP.md, 24])} />
			<div style={stylex()} id={`browse-select-${currentEpd()}`}>
				<RepertoireMovesTable
					{...{
						usePeerRates,
						allLocalStockfish: allLocalStockfish(),
						activeSide: activeSide()!,
						side: currentSide()!,
						responses: tableResponses,
					}}
				/>
			</div>
			<Spacer between={["table", "actions"]} />
			<BrowserActions />
		</div>
	);
};

registerViewMode(RepertoireBrowser, "browse");

export const BrowserActions = () => {
	const currentLine = () => BROWSING_STATE().chessboard.getMoveLog();
	const currentEpd = () => BROWSING_STATE().chessboard.getCurrentEpd();
	const _numDue = () =>
		REPERTOIRE_STATE().numMovesDueFromEpd?.[BROWSING_STATE().activeRepertoireId!]?.[
			BROWSING_STATE().chessboard.getCurrentEpd()
		];

	const _repertoire = () => BROWSING_STATE().getActiveRepertoire();

	const buttonsSig = createMemo(() => {
		const buttons: SidebarAction[] = [];
		buttons.push({
			onPress: () => {
				quick((_s) => {
					trackEvent("browse.practice_from_here");
					UI().pushView(PreReview, {
						props: {
							repertoireId: BROWSING_STATE().activeRepertoireId!,
							startEpd: currentEpd(),
							startLine: currentLine(),
						},
					});
				});
			},
			text: "Practice from here",
			style: "primary",
		});
		return buttons;
	});
	return (
		<div style={stylex(c.column, c.fullWidth)}>
			<SidebarActions actions={buttonsSig()} />
		</div>
	);
};
