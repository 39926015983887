import type { ToastPromotionDismissed } from "~/components/ToastPromotion";

export type UserFlag =
	| "quiz_plans"
	| "multiple_repertoires"
	| "pausing_moves"
	| "course_creation"
	| "courses"
	| "model_games"
	| "move_exclusion"
	| "amateur_games"
	| "characteristics"
	| "bookmarks"
	| "survival"
	| "bonus_moves"
	| ToastPromotionDismissed;

export const DEVELOPMENT_FLAGS: UserFlag[] = [
	"course_creation",
	"courses",
	"characteristics",
	"move_exclusion",
	"survival",
	"bonus_moves",
]; //  ["course_creation", "courses"];
export const ADMIN_FLAGS: UserFlag[] = [
	"course_creation",
	"courses",
	"amateur_games",
	"bookmarks",
	"survival",
	"bonus_moves",
];
export const STAGING_FLAGS: UserFlag[] = ["course_creation", "courses"];
export const BETA_FLAGS: UserFlag[] = [];
export const GENERAL_FLAGS: UserFlag[] = [
	"multiple_repertoires",
	"model_games",
	"courses",
	"characteristics",
	"move_exclusion",
	"amateur_games",
	"bookmarks",
	"bonus_moves",
];

export const BETA_FEATURES: { flag: UserFlag; name: string; description: string }[] = [
	// {
	// 	flag: "quiz_plans" as UserFlag,
	// 	name: "Quiz plans",
	// 	description: "Practice middlegame plans during review",
	// },
	// {
	// 	flag: "bookmarks" as UserFlag,
	// 	name: "Bookmarks",
	// 	description: "Save common positions for easy access",
	// },
];
