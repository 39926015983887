import { type ComponentProps, Show, createMemo } from "solid-js";
import { Spacer } from "~/components/Space";
import type { EnrichedComponent } from "~/types/View";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { isDevelopment } from "~/utils/env";
import { registerViewMode } from "~/utils/register_view_mode";
import { useViewCoursesAction } from "~/utils/sharedActions";
import { onBack } from "~/utils/signals/onBack";
import { trackEvent } from "~/utils/trackEvent";
import { AdvancedRepertoireSettings } from "./AdvancedRepertoireSettings";
import { type LabeledBarIdentifier, genLabeledBarKey } from "./AnimatedBarPiece";
import { BigBar } from "./BigBar";
import { FooterActions } from "./FooterActions";
import { PreAdd } from "./PreAdd";
import { PreReview } from "./PreReview";
import { RepertoireBuilder } from "./RepertoireBuilder";
import { RepertoireCharacteristics } from "./RepertoireCharacteristics";
import { SidebarHeader } from "./RepertoireEditingHeader";
import { ReviewText } from "./ReviewText";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { ChooseImportSourceOnboarding, TrimRepertoireOnboarding } from "./SidebarOnboarding";
import { SidebarTemplate } from "./SidebarTemplate";
import { TemplateSidebarView } from "./TemplateSidebarView";

export const RepertoireOverview: EnrichedComponent = () => {
	onBack(() => {
		quick((_s) => {
			animateSidebar("left");
		});
	});

	const side = () => BROWSING_STATE().activeSide;
	const _textClasses = "text-primary font-semibold";
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const repertoire = () => BROWSING_STATE().getActiveRepertoire()!;
	const biggestMiss = () => REPERTOIRE_STATE().repertoireGrades[activeRepertoireId()!]?.biggestMiss;
	const grade = () => REPERTOIRE_STATE().repertoireGrades[activeRepertoireId()!];
	const numMoves = () => REPERTOIRE_STATE().numMyMoves?.[activeRepertoireId()!] ?? 0;
	const empty = () => numMoves() === 0;
	const numMovesDueFromHere = () =>
		REPERTOIRE_STATE().numMovesDueFromEpd[activeRepertoireId()!]?.[START_EPD];
	const earliestDueDate = () =>
		REPERTOIRE_STATE().earliestReviewDueFromEpd[activeRepertoireId()!]?.[START_EPD];

	const functionallyEmpty = () => REPERTOIRE_STATE().numMyEnabledMoves[activeRepertoireId()!] === 0;
	const _genBarKey = (name: LabeledBarIdentifier) => genLabeledBarKey(name, activeRepertoireId());
	const _progressState = () => BROWSING_STATE().repertoireProgressState[activeRepertoireId()];
	const _mastery = () => REPERTOIRE_STATE().masteryFromEpd[activeRepertoireId()][START_EPD];

	const buildActions = createMemo(() => {
		const actions: SidebarAction[] = [];
		// let promptToGoDeeper = BROWSING_STATE().shouldPromptToGoDeeper();
		// if (promptToGoDeeper) {
		// 	actions.push({
		// 		text: "Increase your coverage goal",
		// 		style: "primary",
		// 		right: (
		// 			<div class="row items-center space-x-2 text-secondary text-xs group-hover:text-primary transition-colors">
		// 				<i class="fa-solid fa-arrow-right " />
		// 			</div>
		// 		),
		// 		onPress: () => {
		// 			quick((_s) => {
		// 				UI().pushView(IncreaseCoverageGoal);
		// 			});
		// 		},
		// 	});
		// }
		actions.push({
			onPress: () => {
				quick((_s) => {
					BROWSING_STATE().setActiveRepertoire(activeRepertoireId()!);
					if (empty()) {
						UI().pushView(RepertoireBuilder);
						BROWSING_STATE().setActiveRepertoire(activeRepertoireId()!);
						trackEvent("side_overview.start_building");
					} else {
						UI().pushView(PreAdd);
						trackEvent("side_overview.keep_building");
					}
				});
			},
			// right: numMoves() > 0 ? `${numMoves()} Added` : undefined,
			right: (
				<div class="row items-center gap-2 text-secondary font-semibold">
					<Show when={numMoves() > 0}>
						<p> {numMoves()} Saved</p>
					</Show>
				</div>
			),
			text: empty()
				? "Start building your repertoire"
				: biggestMiss()
					? "Continue building"
					: "Continue building",
			style: "primary",
		});
		if (empty()) {
			actions.push({
				text: empty() ? "Import" : "Import / export",
				onPress: () => {
					quick((_s) => {
						if (empty()) {
							trackEvent("side_overview.import");
							UI().pushView(ChooseImportSourceOnboarding, {
								props: { side: side() },
							});
							return;
						}
						UI().pushView(TemplateSidebarView, {
							props: {
								header: "Import / export",
								actions: [
									{
										onPress: () => {
											trackEvent("side_overview.import");
											UI().pushView(ChooseImportSourceOnboarding, {
												props: { side: side() },
											});
										},
										text: "Import",
										style: "secondary",
									},
									{
										onPress: () => {
											quick((s) => {
												trackEvent("side_overview.export");
												s.repertoireState.exportPgn(repertoire().id);
											});
										},
										text: "Export to PGN",
										style: "secondary",
									},
								],
							},
						});
					});
				},
				style: "primary",
			});

			const viewCoursesAction = useViewCoursesAction({
				epd: START_EPD,
				repertoireId: BROWSING_STATE().activeRepertoireId!,
			})();
			if (viewCoursesAction) {
				actions.push({ ...viewCoursesAction, style: "primary" });
			}
		}

		// if (!isNil(biggestMiss())) {
		// 	actions.push({
		// 		onPress: () => {
		// 			quick((_s) => {
		// 				// Logic for going to biggest gap and editing
		// 				// You'll need to implement this based on your application's structure
		// 			});
		// 		},
		// 		text: "Address biggest gap",
		// 		style: "primary",
		// 	});
		// }

		return actions;
	});

	const reviewActions = createMemo(() => {
		const actions: SidebarAction[] = [];
		if (!functionallyEmpty()) {
			actions.push({
				onPress: () => {
					quick((_s) => {
						trackEvent("side_overview.start_review");
						UI().pushView(PreReview, {
							props: { repertoireId: activeRepertoireId() ?? null },
						});
					});
				},
				text: "Continue learning",
				// right: (
				// ),
				right: (
					<div class="row items-center gap-2 text-secondary">
						<ReviewText
							date={earliestDueDate()}
							numDue={numMovesDueFromHere()}
							textColor="text-secondary"
						/>
					</div>
				),
				style: "primary",
			});
			// actions.push({
			// 	onPress: () => {
			// 		quick((_s) => {
			// 			trackEvent("side_overview.view_plans_and_model_games");
			// 			UI().pushView(PlansBrowser);
			// 			BROWSING_STATE().startBrowsing(activeRepertoireId()!, "plans_and_model_games");
			// 		});
			// 	},
			// 	text: "View plans and model games",
			// 	style: "primary",
			// });
		}
		return actions;
	});

	const miscActions = createMemo(() => {
		const actions: ComponentProps<typeof FooterActions>["actions"] = [];
		actions.push({
			text: "Repertoire settings...",
			onPress: () => {
				quick((_s) => {
					UI().pushView(AdvancedRepertoireSettings);
				});
			},
		});
		return actions;
	});

	// You can then combine these getters if needed:
	return (
		<SidebarTemplate header={`${repertoire()?.name} repertoire`} actions={[]} bodyPadding={false}>
			<Spacer height={12} />
			<Show when={empty()}>
				<SidebarActions actions={buildActions()} />
				<Spacer between={["actions", "footer-actions"]} />
				<FooterActions actions={miscActions()} />
			</Show>
			<Show when={!empty()}>
				<Show when={grade().tooLarge}>
					<div class="padding-sidebar">
						<div class="flex flex-row gap-4 items-start">
							<i class="fa-solid fa-triangle-exclamation text-yellow-60 text-sm leading-normal"></i>
							<p class="text-yellow-60 text-sm leading-normal font-medium">
								This repertoire is extremely large.
								<br />
								<br />
								This will be very difficult to memorize, and some of our features won't work with a
								repertoire of this size. We recommend you trim this repertoire down.
							</p>
						</div>
						<Spacer between={["body-text", "actions"]} />
					</div>
					<SidebarActions
						actions={[
							{
								text: "Trim this repertoire",
								right: <i class="fa fa-arrow-right text-primary" />,
								style: "primary",
								onPress: () => {
									quick((_s) => {
										trackEvent("too_large.trim");
										UI().pushView(TrimRepertoireOnboarding);
									});
								},
							},
						]}
					/>
					<Spacer between={["actions", "actions"]} />
				</Show>
				<div class="padding-sidebar pb-6">
					<BigBar repertoireId={activeRepertoireId()!} type="completion" />
				</div>
				<SidebarActions actions={buildActions()} />
				<Spacer between={["actions", "bar"]} />
				<div class="padding-sidebar pb-6">
					<BigBar repertoireId={activeRepertoireId()!} type="mastery" />
				</div>
				<SidebarActions actions={reviewActions()} />
				<Show when={grade().characteristics && USER_STATE().flagEnabled("characteristics")}>
					<Spacer height={36} />
					<div class="padding-sidebar">
						<SidebarHeader>
							<h2 class="text-base cursor-default">Repertoire stats</h2>
						</SidebarHeader>
					</div>
					<Spacer height={24} />
					<RepertoireCharacteristics
						class="padding-sidebar"
						repertoireId={activeRepertoireId()}
						exclude={isDevelopment ? ["sharpness"] : ["sharpness", "learnability"]}
					/>
					<Spacer between={["table", "actions"]} />
				</Show>
				<FooterActions actions={miscActions()} />
			</Show>
		</SidebarTemplate>
	);
};

registerViewMode(RepertoireOverview, "side_overview");
