import { BROWSING_STATE, UI } from "~/utils/app_state";
import { trackEvent } from "~/utils/trackEvent";
import { RepertoireBrowser } from "./RepertoireBrowser";
import { Shortcuts } from "./Shortcuts";
import { SidebarTemplate } from "./SidebarTemplate";

export const ReviewShortcuts = (props: { repertoireId: string }) => {
	return (
		<SidebarTemplate
			header="What would you like to practice?"
			actions={[
				{
					text: "Choose something else",
					style: "secondary",
					onPress: () => {
						trackEvent("pre_review.specific");
						UI().pushView(RepertoireBrowser);
						BROWSING_STATE().setActiveRepertoire(props.repertoireId);
					},
				},
			]}
			bodyPadding={false}
		>
			<Shortcuts />
		</SidebarTemplate>
	);
};
