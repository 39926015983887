import { capitalize, isEmpty } from "lodash-es";
import { type Accessor, createMemo } from "solid-js";
import { CourseAvatarStack } from "~/components/CourseAvatarStack";
import { CourseListView } from "~/components/CourseListView";
import { CourseView } from "~/components/CourseView";
import { ReviewText } from "~/components/ReviewText";
import type { SidebarAction } from "~/components/SidebarActions";
import { animateSidebar } from "~/components/SidebarContainer";
import { TargetCoverageReachedView } from "~/components/TargetCoverageReachedView";
import { APP_STATE, BROWSING_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "./app_state";
import { START_EPD } from "./chess";
import { pluralize } from "./pluralize";
import { trackEvent } from "./trackEvent";

export const usePlansAction = (): Accessor<SidebarAction | undefined> => {
	const hasPlans = () => {
		const browsingState = APP_STATE().repertoireState.browsingState;
		const repertoireState = APP_STATE().repertoireState;
		return !isEmpty(
			repertoireState.positionReports[browsingState.activeRepertoireId!]?.[
				browsingState.chessboard.getCurrentEpd()
			]?.plans,
		);
	};
	return () => {
		if (!hasPlans()) {
			return;
		}
		return {
			onPress: () => {
				quick((s) => {
					const bs = s.repertoireState.browsingState;
					animateSidebar("right");
					UI().pushView(TargetCoverageReachedView);
					bs.showPlansState.coverageReached = false;
				});
			},
			text: "How to play from here",
			style: "secondary",
		} as SidebarAction;
	};
};

export const useViewCoursesAction = (options: { epd: string; repertoireId: string }): Accessor<
	SidebarAction | undefined
> => {
	const repertoire = () => REPERTOIRE_STATE().repertoires?.[options.repertoireId];
	const isStartEpd = () => options.epd === START_EPD;
	const positionReport = () =>
		REPERTOIRE_STATE().positionReports[BROWSING_STATE().activeRepertoireId!]?.[options.epd];
	let courses = () => positionReport()?.courses;
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const hasMoves = () => (repertoire()?.positionResponses[options.epd]?.length ?? 0) > 0;
	return () => {
		if (!isEmpty(courses()) && USER_STATE().flagEnabled("courses") && !onboarding().isOnboarding) {
			return {
				style: hasMoves() ? "secondary" : "primary",
				onPress: () => {
					if (courses().length > 1) {
						UI().pushView(CourseListView, { props: { courses: courses() } });
					} else {
						UI().pushView(CourseView, { props: { course: courses()[0] } });
					}
				},
				right: (
					<>
						<CourseAvatarStack courses={courses()} />
					</>
				),
				text: (
					<div class="row items-center space-x-2">
						<p>
							View {pluralize(courses().length, "pre-made repertoire")}{" "}
							{isStartEpd()
								? `for ${capitalize(BROWSING_STATE().activeSide)}`
								: "from this position"}
						</p>
					</div>
				),
			};
		}
	};
};

export const useRecommendedQueueAction = (): Accessor<SidebarAction | undefined> => {
	const activeOptions = createMemo(() => APP_STATE().repertoireState.reviewState.activeOptions);
	const recommendedQueue = createMemo(() => {
		return APP_STATE().repertoireState.reviewState.buildQueue({
			repertoireId: activeOptions()?.repertoireId,
			filter: "recommended",
		});
	});
	return () => {
		if (recommendedQueue().length > 0 && activeOptions()?.filter === "recommended") {
			return {
				onPress: () => {
					quick((s) => {
						UI().cutView();
						s.repertoireState.reviewState.startReview({
							repertoireId: activeOptions()?.repertoireId,
							filter: "recommended",
						});
						trackEvent("post_review.next_common_moves");
					});
				},
				text: "Practice next recommended moves",
				right: <ReviewText numDue={recommendedQueue()?.length ?? 0} />,
				style: "secondary",
			};
		}
		return undefined;
	};
};
