import { isEmpty, max, some, take } from "lodash-es";
import { For, Index, type JSXElement, Show, createMemo, createSignal } from "solid-js";
import { createStore, produce } from "solid-js/store";
import { useHovering } from "~/mocks";
import { clsx } from "~/utils/classes";
import { c, stylex } from "~/utils/styles";
import { CMText } from "./CMText";
import { FadeInOut } from "./FadeInOut";
import { Intersperse } from "./Intersperse";
import { Pressable } from "./Pressable";
import { Spacer } from "./Space";

type SidebarTableColumnWidth = number | "auto" | ["dynamic", number];

export type SidebarTableColumn<T> = {
	label: string;
	width: SidebarTableColumnWidth;
	labelStyle?: "hidden" | "primary" | "standard";
	align?: "left" | "center" | "right";
	alignVertical?: "top" | "center" | "bottom";
	render: (row: T, opts: { callbacks: CellCallbacks; index: number }) => JSXElement;
};

type CellCallbacks = {
	onRender: (e: HTMLElement) => void;
};

let alignmentToClass = (align: "left" | "center" | "right") => {
	switch (align) {
		case "left":
			return "text-start items-start";
		case "center":
			return "text-center items-center";
		case "right":
			return "text-end items-end";
	}
};

let verticalAlignToClass = (align: "top" | "center" | "bottom") => {
	switch (align) {
		case "top":
			return "justify-start";
		case "center":
			return "justify-center";
		case "bottom":
			return "justify-end";
	}
};

const SPACING_CLASS = "space-x-1 xs:space-x-2 xs:space-x-4";

export const SidebarTable = <T,>(props: {
	title?: string;
	class?: string;
	rightColumns: SidebarTableColumn<T>[];
	overrideRightColumns?: SidebarTableColumn<T>[];
	leftColumns: SidebarTableColumn<T>[];
	hideAllLabels?: boolean;
	rows: T[];
	onClick?: (row: T) => void;
	onHover?: (row: T) => void;
	onBlur?: (row: T) => void;
	isFaded?: (row: T) => boolean;
	isClickable?: (row: T) => boolean;
	renderBelow?: (row: T) => JSXElement;
	maxRows?: number | null;
	isSelected?: (row: T) => boolean;
}) => {
	const [expanded, setExpanded] = createSignal(false);
	const maxRows = () => (props.maxRows === null ? props.rows.length : (props.maxRows ?? 5));
	const expandable = () => props.rows.length > maxRows();
	const rows = createMemo(() => {
		if (!expanded()) {
			return take(props.rows, maxRows());
		}
		return props.rows;
	});
	const footerActions = () => {
		return [
			{
				onPress: () => {
					setExpanded(!expanded());
				},
				text: expanded() ? "Hide" : "Show more",
			},
		];
	};
	type RenderedColumnWidths = { left: (number | undefined)[][]; right: (number | undefined)[][] };
	const [columnCellWidths, setColumnCellWidths] = createStore<RenderedColumnWidths>({
		left: [],
		right: [],
	});
	const onCellRender = (
		col: number,
		row: number,
		side: "left" | "right",
		{ element }: { element: HTMLElement },
	) => {
		requestAnimationFrame(() => {
			const width = element.getBoundingClientRect().width;
			setColumnCellWidths(
				produce((draft) => {
					if (!draft[side][col]) {
						draft[side][col] = [];
					}
					draft[side][col][row] = width;
				}),
			);
		});
	};
	const isClickable = (row: T) => props.isClickable?.(row) ?? true;
	const getColumnWidthStyles = (i: number, side: "left" | "right"): any => {
		const columns = side === "left" ? props.leftColumns : props.rightColumns;

		let column = columns[i];
		if (column.width === "auto") {
			return stylex(c.width("auto"), c.flexShrink(1));
		}
		if (Array.isArray(column.width) && column.width[0] === "dynamic") {
			let maxCellWidth = max(columnCellWidths[side][i]) ?? undefined;
			return stylex(c.minWidth(Math.max(column.width[1], maxCellWidth ?? 0)));
		}
		return stylex(c.width(column.width));
	};
	const getLabelStyles = (labelStyle: SidebarTableColumn<any>["labelStyle"]) => {
		const sharedStyles = "font-medium shrink-0";
		switch (labelStyle) {
			case "hidden":
				return "";
			case "primary":
				return clsx(sharedStyles, "text-primary text-sm font-medium");
			case "standard":
				return clsx(sharedStyles, "text-tertiary text-xs font-medium");
		}
	};
	return (
		<div class="w-full flex">
			<Show when={!props.hideAllLabels}>
				<div class="flex row w-full  pb-3 padding-sidebar">
					<Show when={props.title}>
						<div class="text-primary text-base font-bold">{props.title}</div>
					</Show>
					<Show when={props.leftColumns}>
						<div class={clsx("flex row", SPACING_CLASS)}>
							<For each={props.leftColumns}>
								{(column, i) => (
									<div
										class={clsx(
											"text-start shrink-0 cursor-default",
											getLabelStyles(column.labelStyle ?? "standard"),
										)}
										style={getColumnWidthStyles(i(), "left")}
									>
										<Show when={!(column.labelStyle === "hidden")}>{column.label}</Show>
									</div>
								)}
							</For>
						</div>
					</Show>
					<Show when={props.rightColumns}>
						<Spacer grow />
						<div
							class={clsx(
								"flex row relative transition-opacity cursor-default",
								SPACING_CLASS,
								!isEmpty(props.overrideRightColumns) ? "opacity-0" : "opacity-100",
							)}
						>
							<For each={props.rightColumns}>
								{(column, i) => {
									return (
										<div
											class={clsx(
												"text-start shrink-0",
												getLabelStyles(column.labelStyle ?? "standard"),
												alignmentToClass(column.align ?? "right"),
											)}
											style={getColumnWidthStyles(i(), "right")}
										>
											<Show when={!(column.labelStyle === "hidden")}>{column.label}</Show>
										</div>
									);
								}}
							</For>
						</div>
					</Show>
				</div>
			</Show>
			<div class={"h-px w-full"} style={stylex(c.bg(c.colors.sidebarBorder))} />
			<Intersperse
				each={rows}
				separator={() => <div class={"h-px w-full"} style={stylex(c.bg(c.colors.sidebarBorder))} />}
			>
				{(row, y) => {
					const { hoveringProps, hoveringRef } = useHovering(
						() => {
							props.onHover?.(row());
						},
						() => {
							props.onBlur?.(row());
						},
					);
					return (
						<div
							class={clsx(
								"flex w-full padding-sidebar gap-2 rounded-sm py-3 transition-colors group",
								isClickable(row()) && "&hover:bg-gray-18 cursor-pointer",
								props.isFaded?.(row()) ? "opacity-50" : null,
								props.isSelected?.(row()) ? "bg-gray-22 &hover:bg-gray-22" : null,
								props.class,
							)}
							use:onClick={() => {
								if (props.isClickable && !props.isClickable(row())) {
									return;
								}
								props.onClick?.(row());
							}}
							{...hoveringProps}
							ref={hoveringRef}
						>
							<div class={clsx(" row flex row w-full  justify-between relative", SPACING_CLASS)}>
								<Show when={props.leftColumns}>
									<div class="flex grow">
										<SidebarTableCells
											index={y}
											side="left"
											getWidthStyles={(x) => getColumnWidthStyles(x, "left")}
											columns={props.leftColumns!}
											row={row()}
											onRender={(e, x) => onCellRender(x, y, "left", { element: e })}
										/>
									</div>
								</Show>
								<FadeInOut
									open={!isEmpty(props.overrideRightColumns)}
									class="absolute right-0 inset-y-0 flex row"
								>
									<For each={props.overrideRightColumns}>
										{(_column, _i) => {
											return (
												<SidebarTableCells
													index={y}
													side="right"
													columns={props.overrideRightColumns!}
													row={row()}
													getWidthStyles={(x) => getColumnWidthStyles(x, "right")}
													onRender={(e, x) => onCellRender(x, y, "right", { element: e })}
												/>
											);
										}}
									</For>
								</FadeInOut>
								<Show when={!isEmpty(props.rightColumns)}>
									<div
										class={clsx(
											"shrink-0",
											!isEmpty(props.overrideRightColumns)
												? "opacity-0 pointer-events-none"
												: "opacity-100",
										)}
									>
										<SidebarTableCells
											index={y}
											side="right"
											columns={props.rightColumns!}
											row={row()}
											getWidthStyles={(x) => getColumnWidthStyles(x, "right")}
											onRender={(e, x) => onCellRender(x, y, "right", { element: e })}
										/>
									</div>
								</Show>
								<Show when={props.isSelected}>
									<i
										class={clsx(
											"fa fa-check transition-opacity",
											!props.isSelected!(row()) && "opacity-0",
										)}
										style={stylex(c.fg(c.colors.text.primary))}
									/>
								</Show>
							</div>
							<Show when={props.renderBelow}>
								{(renderBelow) => {
									return renderBelow()(row());
								}}
							</Show>
						</div>
					);
				}}
			</Intersperse>
			<div class={"h-px w-full"} style={stylex(c.bg(c.colors.sidebarBorder))} />

			<Show when={expandable()}>
				<div style={stylex(c.row)} class={clsx("pt-3 space-x-4 padding-sidebar")}>
					<For each={footerActions()}>
						{(action) => (
							<Pressable
								class={clsx("pb-1")}
								onPress={(e: Event) => {
									e.stopPropagation();
									action.onPress();
								}}
							>
								<CMText
									style={stylex(c.fontSize(12), c.weightSemiBold)}
									class="text-tertiary &hover:text-primary transition-colors"
								>
									{action.text}
								</CMText>
							</Pressable>
						)}
					</For>
				</div>
			</Show>
		</div>
	);
};

export const SidebarTableCells = <T,>(props: {
	columns: SidebarTableColumn<T>[];
	index: number;
	row: T;
	side: "left" | "right";
	getWidthStyles: (x: number) => number;
	onRender?: (e: HTMLElement, x: number) => void;
}) => {
	let anyAuto = () => some(props.columns, (col) => col.width === "auto");
	return (
		<div class={clsx("flex row", SPACING_CLASS, anyAuto() ? "shrink-1" : "shrink-0")}>
			<Index each={props.columns}>
				{(column, x) => {
					// let render = untrack(column).render;
					return (
						<div
							style={stylex(props.getWidthStyles(x))}
							class={clsx(
								" text-secondary font-medium text-xs flex ",
								verticalAlignToClass(column().alignVertical ?? "center"),
								alignmentToClass(column().align ?? props.side),
								column().width === "auto" ? "shrink-1 grow" : "shrink-0",
							)}
						>
							{column().render(props.row, {
								index: props.index,
								callbacks: {
									onRender: (e: HTMLElement) => {
										props.onRender?.(e, x);
									},
								},
							})}
						</div>
					);
				}}
			</Index>
		</div>
	);
};
