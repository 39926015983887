import { Chess } from "@lubert/chess.ts";
import { every, filter, isEmpty } from "lodash-es";
import { type Accessor, Show, createMemo } from "solid-js";
import type { Bookmark } from "~/types/Bookmark";
import { Epd } from "~/types/Epd";
import { Line } from "~/types/Line";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, USER_STATE, useMode } from "~/utils/app_state";
import { createChessProxy } from "~/utils/chess_proxy";
import { clsx } from "~/utils/classes";
import { isMobile } from "~/utils/isMobile.tsx";
import { MasteryProgressBar } from "./MasteryProgressBar";
import { PreReview } from "./PreReview";
import { RepertoireBuilder } from "./RepertoireBuilder";
import { SidebarTable, type SidebarTableColumn } from "./SidebarTable";
import { TableCoverageProgressBar, useCoverageProgress } from "./TableCoverageProgressBar";

type EnrichedBookmark = {
	shortcut: Bookmark;
	mastery: number;
	progresS: number;
	completed: boolean;
	numMoves: number;
};

export const Shortcuts = () => {
	const mode = useMode();
	const bookmarks = () => {
		if (!USER_STATE().flagEnabled("bookmarks")) {
			return [];
		}
		const activeRepertoireId = BROWSING_STATE().activeRepertoireId!;
		let bookmarks = REPERTOIRE_STATE().autoBookmarks[activeRepertoireId];
		if (!bookmarks || isEmpty(bookmarks)) {
			return [];
		}
		return filter(bookmarks, (b) => b.repertoireId === activeRepertoireId);
	};
	const enrichedBookmarks: Accessor<EnrichedBookmark[]> = createMemo(() => {
		const activeRepertoireId = BROWSING_STATE().activeRepertoireId!;
		return bookmarks().map((bookmark) => {
			let mastery = REPERTOIRE_STATE().masteryFromEpd[activeRepertoireId][bookmark.epd];
			let { completed, progress } = useCoverageProgress(bookmark.epd);
			let numMoves = REPERTOIRE_STATE().numMovesFromEpd[activeRepertoireId][bookmark.epd];
			return {
				shortcut: bookmark,
				mastery: mastery,
				progresS: progress,
				completed: completed,
				numMoves: numMoves,
			};
		});
	});
	const rightColumns = () => {
		let useReviewView = mode() === "browse" || mode() === "review";
		let columns: SidebarTableColumn<EnrichedBookmark>[] = [];
		let masteryColumn = {
			label: "Mastery",
			render: (bookmark: EnrichedBookmark) => {
				return <MasteryProgressBar epdAfter={bookmark.shortcut.epd} />;
			},
			width: 80,
		};
		let coverageColumn = {
			label: "Coverage",
			render: (bookmark: EnrichedBookmark) => {
				return <TableCoverageProgressBar epdAfter={bookmark.shortcut.epd} />;
			},
			width: 80,
		};
		// let movesColumn = {
		// 	label: "Moves",
		// 	render: (bookmark: EnrichedBookmark) => {
		// 		return <p class="text-secondary font-medium">{bookmark.numMoves}</p>;
		// 	},
		// 	width: 80,
		// };
		let allCompleted = every(enrichedBookmarks(), (b) => b.completed);
		// columns.push(movesColumn);
		if (useReviewView) {
			columns.push(masteryColumn);
		} else {
			if (!allCompleted || !isMobile()) {
				columns.push(coverageColumn);
			}
		}
		return columns;
	};

	return (
		<Show when={!isEmpty(bookmarks())}>
			<div
				onMouseLeave={() => {
					UI()
						.getActiveChessboard()
						.set((c) => {
							c.previewPosition = null;
						});
				}}
			>
				<SidebarTable
					onHover={(bookmark: EnrichedBookmark) => {
						UI()
							.getActiveChessboard()
							.set((c) => {
								c.previewPosition = createChessProxy(new Chess(Epd.toFen(bookmark.shortcut.epd)));
							});
					}}
					rows={enrichedBookmarks()}
					onClick={(enrichedBookmark: EnrichedBookmark) => {
						let bookmark = enrichedBookmark.shortcut;

						if (mode() === "browse") {
							BROWSING_STATE().setActiveRepertoire(bookmark.repertoireId);
							if (bookmark.line.length > 0) {
								UI().getActiveChessboard().playLine(bookmark.line);
							}
							UI().pushView(PreReview, {
								props: {
									repertoireId: bookmark.repertoireId,
									startEpd: bookmark.epd,
									startLine: bookmark.line,
								},
							});
						} else {
							UI().pushView(RepertoireBuilder);
							BROWSING_STATE().setActiveRepertoire(bookmark.repertoireId);
							UI().getActiveChessboard().playLine(bookmark.line);
						}
					}}
					maxRows={null}
					leftColumns={[
						{
							label: "Shortcuts",
							labelStyle: "primary",
							render: (enrichedBookmark: EnrichedBookmark) => {
								let bookmark = enrichedBookmark.shortcut;
								return (
									<div class="flex flex-col gap-3 items-start w-full">
										<div class={clsx("flex flex-row justify-between w-full")}>
											<div class="flex flex-col items-start">
												<p
													class={clsx(
														`text-secondary font-medium text-xs line-clamp-1 overflow-visible`,
													)}
												>
													{bookmark.ecoCode.fullName.includes(":")
														? `${bookmark.ecoCode.fullName}`
														: bookmark.ecoCode.fullName}
												</p>
												<p class={clsx(`text-secondary font-medium hidden`)}>
													{Line.toPgn(bookmark.line)}
												</p>
											</div>
										</div>
									</div>
								);
							},
							width: "auto",
						},
					]}
					rightColumns={rightColumns()}
				/>
			</div>
		</Show>
	);
};
